import millify from 'millify'
import { Typography, Row, Col, Statistic } from 'antd'
import { Link } from 'react-router-dom'
import { useGetCryptosQuery } from '../services/cryptoApi'
import {CryptoCurrencies , News} from '../components'
import Loader from './Loader'

const { Title } = Typography

const Homepage = () => {
  const { data, isFetching } = useGetCryptosQuery(50)
  

  const globalsStats = data?.data?.stats!

  if (isFetching) return (<Loader />)

  return (
    <>
      <Title level={2} className="heading">Global Crypto Stats</Title>
      <Row>
        <Col span={12}><Statistic title="Total Cryptocurrencies" value={millify(globalsStats.total)} /></Col>
        <Col span={12}><Statistic title="Total Exchanges" value={millify(globalsStats.totalExchanges)} /></Col>
        <Col span={12}><Statistic title="Total Market Cap" value={millify(Number(globalsStats.totalMarketCap))} /></Col>
        <Col span={12}><Statistic title="Total 24h Volume" value={millify(Number(globalsStats.total24hVolume))} /></Col>
        <Col span={12}><Statistic title="Total Markets" value={millify(globalsStats.totalMarkets)} /></Col>
      </Row>
      <div className="home-heading-container">
        <Title level={2} className="home-title">Top 10 Cryptocurrencies in the world</Title>
        <Title level={3} className="show-more"><Link to="/cryptocurrencies">Show More</Link></Title>
      </div>
      <CryptoCurrencies simplified/>
      <div className="home-heading-container">
        <Title level={2} className="home-title">Latest Crypto News</Title>
        <Title level={3} className="show-more"><Link to="/news">Show More</Link></Title>
      </div>
      <News simplified/>
    </>
  )
}

export default Homepage